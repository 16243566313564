<template>
	<v-container class="main-container--new-user">
		<v-col>
			<AdminDefaultHeader>New User</AdminDefaultHeader>
		</v-col>
		<v-form ref="form">
			<v-row>
				<v-col cols="6">
					<InputTextField
						:id="'new_user_first_name'"
						:rules="[rules.required]"
						v-model="newUserFirstName"
					>
						<template #label>First Name</template>
					</InputTextField>
				</v-col>
				<v-col cols="6">
					<InputTextField
						:id="'new_user_last_name'"
						v-model="newUserLastName"
						:rules="[rules.required]"
					>
						<template #label>Last Name</template>
					</InputTextField>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<InputSelect
						:id="'new_user_role'"
						v-model="newUserRole"
						:items="userRoles"
						:itemText="'name'"
						:itemValue="'level'"
						:rules="[rules.required]"
					>
						<template #label>Role</template></InputSelect
					>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<InputTextField
						:id="'new_user_email'"
						v-model="newUserEmail"
						:type="'email'"
						:rules="[rules.required, rules.email]"
					>
						<template #label>Email</template>
					</InputTextField>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn
						class="admin-primary-button secondary-contrast-background"
						:loading="isLoading"
						@click="sendOrganizationIvite"
						>Send Invite</v-btn
					>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-alert v-if="message" :type="status">{{
						message
					}}</v-alert>
				</v-col>
			</v-row>

			<v-row>
				<v-col v-if="link">
					<small>Your user registration link:</small>
					<div @click="copyText" class="copy-to-clipboard">
						{{ link }}
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="organizationLink">
					<small>Your user organization homepage link:</small>
					<div @click="copyText" class="copy-to-clipboard">
						{{ organizationLink }}
					</div></v-col
				></v-row
			>
		</v-form>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputTextField from "@/components/InputTextField.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";

export default {
	name: "NewUser",
	components: {
		AdminDefaultHeader,
		AdminDefaultDescription,
		InputSelect,
		InputTextField
	},
	computed: {
		...mapGetters(["organization", "userId", "isLoading"])
	},
	data() {
		return {
			newUserFirstName: "",
			newUserLastName: "",
			newUserRole: "",
			newUserEmail: "",
			message: "",
			status: "",
			link: null,
			linkHidden: true,
			organizationLink: null,
			userRoles: [],
			rules: {
				required: value => !!value || "Required.",
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail.";
				}
			}
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async setIsLoading(val) {
			this.$store.dispatch("setIsLoading", val);
		},

		async getOrganizationUserRoles() {
			let response = null;
			await this.setIsLoading(true);
			try {
				response = await get("/organization_user_roles");
				const {
					data: { data }
				} = response;
				this.userRoles = data;
			} catch (error) {
				console.error(error);
			}
			await this.setIsLoading(false);
		},
		async sendOrganizationIvite() {
			let response = null;
			try {
				if ((await this.validate()) === false) {
					this.message = "Check fields";
					this.status = "error";
					return;
				}
				await this.setIsLoading(true);
				const payload = {
					organization_id: this.organization.id,
					first_name: this.newUserFirstName,
					last_name: this.newUserLastName,
					organization_user_role_id: 1,
					email: this.newUserEmail
				};
				response = await post("/org_user_invite", payload);
				this.message = "The invitation has been sent";
				this.status = "success";
			} catch (error) {
				console.error(error);
				this.status = "error";
				this.message = "A problem occurred, try again";
			}
			await this.setIsLoading(false);
			const {
				data: { data },
				status: { status }
			} = response;
			await this.linkInfo(response);
		},
		linkInfo(response) {
			const id = response.data.data.uuid;
			const orgId = response.data.data.organization_id;
			this.link = `${document.location.origin}?organizationId=${orgId}`;
			this.organizationLink = `${document.location.origin}/register-student/?userId=${id}&organizationId=${orgId}`;
		},
		copyText(e) {
			const val = e.target.value;
			navigator.clipboard.writeText(val);
		}
	},
	created() {
		this.getOrganizationUserRoles();
	}
};
</script>

<style></style>
